import React from 'react'
import NavbarTopFirst from '../Navbar/NavbarTopFirst';
import Footer from '../Footer/Footer';
import { FaHandshake, FaCheckCircle, FaCogs, FaDollarSign } from 'react-icons/fa';
import { useMediaQuery } from "react-responsive"; // Importing useMediaQuery hook from react-responsive
import { ChakraProvider } from '@chakra-ui/react';
import { FaHome } from "react-icons/fa";
import { MdMarkEmailRead } from "react-icons/md";
import { IoIosPhonePortrait } from "react-icons/io";
import Appointment from './Appointment';
import { Helmet } from "react-helmet";


// Define shadow styles
const shadowStyle = {
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Adding shadow effect
  padding: '20px',
  borderRadius: '10px',
  backgroundColor: 'white',
};

// Additional styles for sections
const sectionShadowStyle = {
  ...shadowStyle,
  marginBottom: '20px',
};



// rfce
function About() {

  const companyName = "UpholdLegal"; //process.env.REACT_APP_COMPANY_NAME;
  const titleColour = "#008080"; //process.env.REACT_APP_TITLE_COLOUR; //
  const textColur = "#07080a";//process.env.REACT_APP_TEXT_COLOUR; //
  const bgColur = "#white";//process.env.REACT_APP_BG_COLOUR;//
  const otherColur = "#008080" //process.env.REACT_APP_THIRD_COLOUR;//
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 480px)" });



  console.log(isTabletOrMobile, 'otherColur----')

  return (

    <>

      <Helmet>
        <title>About- UpholdLegal</title>
        <meta name="description" content="Learn more about UpholdLegal, our mission, and the professional legal services we offer." />
        <meta name="keywords" content="what is uphold legal,upholdLegal,upholdlegal about,Uphold Legal about,what is legal services,i want to know about legal service,legal services,about legal services provider,legal services,uphold legal services" />
        <link rel="canonical" href="https://www.upholdlegal.com/about" />
      </Helmet>
      <NavbarTopFirst />

      <div style={{ position: 'relative', width: '100%', height: '100px', backgroundColor: "#008080", display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1%', marginTop: '130px' }}>
        <h1 style={{ margin: '0', fontWeight: 'bold', color: 'white', fontSize: '2.5rem' }}>About Us</h1>
      </div>

      <img
        src="./court6.jpeg"
        alt="Placeholder Image"
        className="about-image"
        style={{ width: '100%', height: isTabletOrMobile ? '30vh' : '50vh', objectFit: 'cover' }}
      />

      <div style={{ padding: isTabletOrMobile ? '20px' : '20px', backgroundColor: { bgColur } }}>
        <div>
          <div style={{ ...shadowStyle, marginTop: '-15px' }}>
            <p>
              <h1 style={{ paddingTop: '20px', color: "#008080", fontWeight: 'bold' }}><strong>Welcome to {companyName}</strong></h1>
            </p>

            <p style={{ paddingTop: '15px', fontStyle: '', arginBottom: '5%', textAlign: 'justify', color: { textColur } }}>
              We ensure the best possible return on investment to accelerate the growth of our clients. We achieve synergy by including the skills and ideas of all our team members. We have a well-placed Information Security System to ensure the security of data and processes for our clients.
            </p>
          </div>
        </div>
        <div style={{ ...shadowStyle, marginTop: '15px' }}>
          <h2 style={{ paddingTop: '20px', color: "#008080" }}><strong>Our Services</strong></h2>

          <ul style={{ paddingTop: '15px', color: 'black', paddingInlineStart: '20px', listStyleType: 'none' }}>
            <li style={{ marginBottom: '10px', fontWeight: 'bold' }}>1. Medical Record Retrieval</li>
            <li style={{ marginBottom: '10px', fontWeight: 'bold' }}>2. Demand Letter</li>
            <li style={{ marginBottom: '10px', fontWeight: 'bold' }}>3. Lien Negotiation</li>
            <li style={{ marginTop: '10px', marginBottom: '10px', fontWeight: 'bold', textJustify: 'inter-word' }}>4. Deposition and Trial Transcript Summaries</li>
          </ul>

          <p style={{ textAlign: 'justify' }}>

            Whether you're looking to build a new website from scratch, develop a mobile app, or integrate chatbots to enhance customer interaction, our team of experts is ready to provide innovative and effective solutions.
          </p>
        </div>
        <div style={{ ...shadowStyle, marginTop: '25px' }}>
          <div style={{ position: 'relative', width: '100%', height: '100px', backgroundColor: "white", display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1%' }}>
            <h1 style={{ margin: '0', fontWeight: 'bold', color: '#008080', fontSize: '2rem' }}>Our Strength</h1>


          </div>
          <p style={{ fontSize: '16px', lineHeight: '1.6', color: '#333333', borderTop: 'none', marginTop: '0', marginLeft: isTabletOrMobile ? '0px' : '60px', marginRight: '0px', textAlign: 'justify', textJustify: 'inter-word', }}>
            We ensure the best possible return on investment to accelerate the growth of our clients. We achieve synergy by including the skills and ideas of all our team members. We have a well-placed Information Security System to ensure the security of data and processes for our clients.</p>

          <div style={{ display: 'grid', gridTemplateColumns: isTabletOrMobile ? '1fr' : '1fr 1fr', gap: '20px', marginTop: '30px' }}>
            <div style={{ display: 'flex', alignItems: 'center', padding: isTabletOrMobile ? '0px' : '10px' }}>

              <div>


                <h2 style={{ display: 'flex', alignItems: 'center', fontSize: '24px' }}>
                  <FaHandshake size={50} style={{ marginRight: '10px', color: '#008080' }} />
                  <span style={{ fontWeight: 'bold', color: '#000000', fontSize: '1.4rem' }}>Trust & Integrity</span>
                </h2>
                <p style={{ fontSize: '16px', lineHeight: '1.6', color: '#333333', borderTop: 'none', marginTop: '0', marginLeft: isTabletOrMobile ? '10px' : '60px', marginRight: '0px', textAlign: 'justify', textJustify: 'inter-word', }}>
                  Our relationships are built on honesty, truthfulness, and mutual respect. Our client relationships are based on honesty and integrity. We gain our clients' trust by being open and honest in all of our conversations.
                </p>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', padding: isTabletOrMobile ? '0px' : '10px' }}>
              <div>
                <h2 style={{ display: 'flex', alignItems: 'center', fontSize: '24px' }}>
                  <FaCheckCircle size={35} style={{ marginRight: '10px', color: '#008080' }} />
                  <span style={{ fontWeight: 'bold', color: '#000000', fontSize: '1.4rem' }}>Quality & Reliability</span>
                </h2>
                <p style={{ fontSize: '16px', lineHeight: '1.6', color: '#333333', borderTop: 'none', marginTop: '0', marginLeft: isTabletOrMobile ? '10px' : '49px', marginRight: '0px', textAlign: 'justify', textJustify: 'inter-word', }}>
                  At the start of the contract, we show the accuracy goal, and we hold ourselves to it. Regardless of the resources allocated to the client project, our priority is consistently delivering high-quality outcomes. We are a trustworthy resource for all of your business solutions, not just an outsourcing partner.
                </p>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', padding: isTabletOrMobile ? '0px' : '10px' }}>
              <div>
                <h2 style={{ display: 'flex', alignItems: 'center', fontSize: '24px' }}>
                  <FaCogs size={50} style={{ marginRight: '10px', color: '#008080' }} />
                  <span style={{ fontWeight: 'bold', color: '#000000', fontSize: '1.4rem' }}>Flexibilityy</span>
                </h2>
                <p style={{ fontSize: '16px', lineHeight: '1.6', color: '#333333', borderTop: 'none', marginTop: '0', marginLeft: isTabletOrMobile ? '10px' : '60px', marginRight: '0px', textAlign: 'justify', textJustify: 'inter-word', }}>
                  At uphold legal we provide all services on a Month-to-Month or project basis, without any long-term contracts. Our services are available as needed, allowing you to adjust, customize, or cancel them at any point. We provide flexibility to adapt to your business's changing needs, free from any fixed or contractual commitments.
                </p>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', padding: isTabletOrMobile ? '0px' : '10px' }}>
              <div>
                <h2 style={{ display: 'flex', alignItems: 'center', fontSize: '24px' }}>
                  <FaDollarSign size={35} style={{ marginRight: '10px', color: '#008080' }} />
                  <span style={{ fontWeight: 'bold', color: '#000000', fontSize: '1.4rem' }}>Cost-effective</span>
                </h2>
                <p style={{ fontSize: '16px', lineHeight: '1.6', color: '#333333', borderTop: 'none', marginTop: '0', marginLeft: isTabletOrMobile ? '10px' : '49px', marginRight: '0px', textAlign: 'justify', textJustify: 'inter-word', }}>
                  Uphold Legal services lower your administrative costs, enhance time management efficiency, and boost your overall profitability. There could be as much as a 60% savings in your payroll without any compromise on quality. This gives you much leverage concentrate more on your core business operations.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
export default About;